import React, { useEffect } from "react";
import "./header.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../modules";
import { AccountMenu, Button } from "../../components";
// import { MenuIcon } from "../../assets/icons";
import { Bars3Icon, SunIcon, MoonIcon } from "@heroicons/react/24/outline";
// import { TransactionIcon } from "../../assets/icons";

export const Header = () => {

  useEffect(() => {
    const current_theme = localStorage.getItem("data-theme");
    const data_theme = document.querySelector("[data-theme]");
    if (current_theme != null) {
      data_theme?.setAttribute("data-theme", current_theme);
    } else {
      localStorage.setItem("data-theme", "dark");
      data_theme?.setAttribute("data-theme", "dark");
    }
  }, []);

  const User = useSelector(selectUserInfo);


  const toggleLeftSidebar = () => {
    const side2 = document.getElementsByClassName("left-sidebar");
    side2[0].classList.toggle("mini");

    const pg_main = document.getElementsByClassName("pg-main");
    pg_main[0].classList.toggle("d-mini");

  }

  const toggleSidebar = () => {

    const side = document.getElementsByClassName("sidebar-wrapper");
    side[0].classList.toggle("show");

    const cLogo = document.getElementsByClassName("c-logo");
    cLogo[0].classList.toggle("mini-logo");
  };

  const toggleTheme = () => {
    const data_theme = document.querySelector("[data-theme]");
    if (data_theme?.getAttribute("data-theme") === "light") {
      data_theme.setAttribute("data-theme", "dark");
      localStorage.setItem("data-theme", "dark");
    } else {
      data_theme?.setAttribute("data-theme", "light");
      localStorage.setItem("data-theme", "light");
    }
  };

  return (
    <>
      <div className="nav">
        <div className="content-left">
          {User.state === "active" && <Bars3Icon className="i24 menu-toggle" onClick={toggleLeftSidebar} />}

          <Link to="/" className="l-link c-logo">
            <img src={"/img/mini-logo.png"} alt="Mini Logo" className="logo" />
          </Link>



        </div>
        <ul className="content-center">
          <li><a href="https://www.puppy-coin-blockchain.com/" target="_blank" rel="noopener noreferrer">Website</a></li>
          <li><a href="https://explorer-puppy-coin.com" target="_blank" rel="noopener noreferrer">Explorer</a></li>

          <li><a href="https://www.puppy-coin-blockchain.com/blank-1" target="_blank" rel="noopener noreferrer" >About </a></li>
          <li><a href="https://t.me/+Mg-EIj77gCk5NTc8"  target="_blank" rel="noopener noreferrer" >Contact</a></li>
        </ul>
        <div className="content-right">
          <span className="theme-mode" onClick={toggleTheme}>
            <MoonIcon className="i24 dark" />
            <SunIcon className="i24 light" />
          </span>
          {User.state !== "active" ? (
            <>
              <div className="auth-btn_wrapper">
                <Link to="/login">
                  <Button
                    size="medium"
                    lable="Login"
                    type="primary"
                    className="outline"
                  />
                </Link>
                <Link to="/signup">
                  <Button size="medium" lable="Register" type="primary" />
                </Link>
              </div>
            </>
          ) : (
            <div className="right-menu">
              <AccountMenu />
            </div>
          )}
          <div className="right-menu-icon" onClick={toggleSidebar}>
            <Bars3Icon className="i24" />
          </div>
        </div>
      </div>
    </>
  );
};
